<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="ค้นหา"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="search"
      :footer-props="footer"
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
      <template v-slot:item.orderId="{ item }">
        <router-link :to="`order/detail/${item.orderId}`">{{
          item.orderId
        }}</router-link>
      </template>
      <template v-slot:item.createdOn="{ item }">
        {{ item.createdAt | formatDateTime }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ item.createdAt | formatMiniDate }}
      </template>
      <template v-slot:item.name="{ item }">
        {{ item.member.firstname }} {{ item.member.lastname }}
      </template>
      <template v-slot:item.type="{ item }">
        {{ item.type | bookingType }}
      </template>
      <template v-slot:item.schedule.date="{ item }">
        {{ item.schedule.date | formatMiniDate }}
      </template>
      <template v-slot:item.time="{ item }">
        {{ formatSchedule(item.schedule.startTime, item.schedule.endTime) }}
      </template>
      <template v-slot:item.orderStatus="{ item }">
        <div
          v-if="item.orderStatus === 'paid' && item.price.summary <= 0"
          :class="statusColor(item.orderStatus)"
        >
          คอนเฟิร์ม
        </div>
        <div v-else :class="statusColor(item.orderStatus)">
          {{ item.orderStatus | formatStatus }}
        </div>
      </template>
      <template v-slot:item.price.total="{ item }">
        {{ item.price ? formatCurrency(item.price.total) : "" }}
      </template>
      <template v-slot:item.price.countYoung="{ item }">
        {{ item.price.countYoung ? item.price.countYoung : 0 }}
      </template>
      <template v-slot:item.price.countStudent="{ item }">
        {{ item.price.countStudent ? item.price.countStudent : 0 }}
      </template>
      <template v-slot:item.price.countAdult="{ item }">
        {{ item.price.countAdult ? item.price.countAdult : 0 }}
      </template>
      <template v-slot:item.price.countSenior="{ item }">
        {{ item.price.countSenior ? item.price.countSenior : 0 }}
      </template>
      <template v-slot:item.price.total="{ item }">
        {{ item.price.total ? formatCurrency(item.price.total) : 0 }}
      </template>
      <template v-slot:item.price.discount="{ item }">
        {{ item.price.discount ? formatCurrency(item.price.discount) : 0 }}
      </template>
      <template v-slot:item.price.summary="{ item }">
        {{ item.price.summary ? formatCurrency(item.price.summary) : 0 }}
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "วันที่ทำรายการ", value: "createdOn", width: "9rem" },
        { text: "หมายเลขออเดอร์", value: "orderId", width: "9rem" },
        { text: "ชื่อจริง", value: "member.firstname", width: "9rem" },
        { text: "นามสกุล", value: "member.lastname", width: "9rem" },
        { text: "ประเภทการจอง", value: "type", width: "9rem", align: "center" },
        { text: "จองวันที่", value: "schedule.date", width: "7rem" },
        { text: "รอบเวลา", value: "time", width: "10rem" },
        { text: "สถานะ", value: "orderStatus", width: "7rem" },
        {
          text: "จำนวนผู้เข้าร่วม",
          value: "joinQty",
          align: "center",
          width: "9rem",
        },
        {
          text: "เด็ก",
          value: "price.countYoung",
          align: "center",
          width: "8rem",
        },
        {
          text: "นักเรียน / นักศึกษา",
          value: "price.countStudent",
          align: "center",
          width: "11rem",
        },
        {
          text: "ผู้ใหญ่",
          value: "price.countAdult",
          align: "center",
          width: "8rem",
        },
        {
          text: "ผู้สูงอายุ",
          value: "price.countSenior",
          align: "center",
          width: "7rem",
        },
        {
          text: "ราคาเต็ม (บาท)",
          value: "price.total",
          align: "center",
          width: "9rem",
        },
        {
          text: "ส่วนลด (บาท)",
          value: "price.discount",
          align: "center",
          width: "9rem",
        },
        {
          text: "รวมทั้งสิ้น (บาท)",
          value: "price.summary",
          align: "center",
          width: "9rem",
        },
      ],
    };
  },
  methods: {
    statusColor(value) {
      switch (value) {
        case "waiting":
          return "orange--text";
        case "pending":
          return "orange--text";
        case "paid":
          return "green--text";
        case "confirmed":
          return "green--text";
        case "canceled":
          return "red--text";
        case "rejected":
          return "red--text";
        default:
          return "";
      }
    },
  },
};
</script>
