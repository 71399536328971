var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title'),_c('v-data-table',{attrs:{"footer-props":_vm.footer,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"disable-sort":"","disable-filtering":"","disable-pagination":"","no-data-text":"ไม่พบข้อมูล","no-results-text":"ไม่พบข้อมูล"},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm._f("formatMiniDate")(item.date))+" ")])]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm.formatSchedule(item.startTime, item.endTime))+" ")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{},[_vm._v(" "+_vm._s(_vm._f("bookingType")(item.type))+" ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"font-weight-bold",attrs:{"color":"green","dark":""},on:{"click":function($event){return _vm.onChooseSchedule(item.scheduleId, item.startTime, item.endTime)}}},[_vm._v("เลือก")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }