<template>
  <v-row>
    <v-col cols="6">
      <v-menu
        v-model="menu1"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(date1)"
            :label="startLabel"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          locale="th"
          v-model="date1"
          @input="onClick('menu2')"
        ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="6">
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(date2)"
            :label="endLabel"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          locale="th"
          :min="date1"
          v-model="date2"
          @input="onClick('menu2')"
        ></v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
import dayjs from "dayjs";
import buddhistEra from "dayjs/plugin/buddhistEra";
import "dayjs/locale/th";
dayjs.locale("th");
dayjs.extend(buddhistEra);
export default {
  props: {
    startLabel: {
      type: String,
      default: "เริ่มต้น",
    },
    endLabel: {
      type: String,
      default: "สิ้นสุด",
    },
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      date1: dayjs().format("YYYY-MM-DD"),
      date2: dayjs().format("YYYY-MM-DD"),
    };
  },
  methods: {
    formatDate(date) {
      if (date) {
        return dayjs(date).format("D MMMM BBBB");
      }
      return "";
    },
    onClick(data) {
      this[data] = false;
      if (this.date2 < this.date1) {
        this.date2 = "";
      }
      if (this.date1 && !this.date2) {
        const arr = [];
        arr.push(this.date1);
        arr.push(this.date1);
        this.$emit("input", arr);
      }
      if (this.date1 && this.date2) {
        const arr = [];
        arr.push(this.date1);
        arr.push(this.date2);
        this.$emit("input", arr);
      }
    },
  },
};
</script>
