<template>
  <v-btn v-bind="attrs" v-on="{ ...$listeners }">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
  </v-btn>
</template>
<script>
export default {
  data() {
    return {
      defaultAttrs: {},
    };
  },
  computed: {
    attrs() {
      return {
        ...this.defaultAttrs,
        ...this.$attrs,
      };
    },
  },
};
</script>
