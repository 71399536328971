<template>
  <v-alert
    v-bind="attrs"
    v-on="{ ...$listeners }"
    dismissible
    border="left"
    elevation="2"
    colored-border
    :color="color"
    :icon="icon"
  >
    {{ title }} <strong>{{ message }}</strong>
  </v-alert>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
      };
    },
    icon() {
      if (this.type === "error") {
        return "mdi-alert";
      }
      return "mdi-check-bold";
    },
    color() {
      if (this.type === "error") {
        return "red";
      }
      return "green";
    },
  },
};
</script>
