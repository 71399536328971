<template>
  <v-data-table
    v-bind="attrs"
    v-on="{ ...$listeners }"
    no-data-text="ไม่พบข้อมูล"
    no-results-text="ไม่พบข้อมูล"
    :headers="headers"
    :items="items"
    :items-per-page="5"
    :footer-props="footer"
    class="elevation-3"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-slot:item.orderId="{ item }">
      <!-- <router-link
        :to="`order/${item.isGroup ? 'group' : 'detail'}/${item.orderId}`"
        >{{ item.orderId }}</router-link
      > -->
      {{ item.orderId }}
    </template>
    <template v-slot:item.createdOn="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
    <template v-slot:item.createdAt="{ item }">
      {{ formatDate(item.createdAt) }}
    </template>
    <template v-slot:item.name="{ item }">
      {{ item.member.firstname }} {{ item.member.lastname }}
    </template>
    <template v-slot:item.organizationName="{ item }">
      {{ item.organizationName ? item.organizationName : "-" }}
    </template>
    <template v-slot:item.member.firstname="{ item }">
      <!-- <div>
        {{ item.member.firstname }}
      </div> -->
      <div v-if="item.participant && item.participant.length > 0">
        <div v-for="(member, index) in item.participant" :key="index">
          {{ member.firstname }}
        </div>
      </div>
      <div v-else-if="item.isGroup">{{ item.contactFirstname }}</div>
      <div v-else>-</div>
    </template>
    <template v-slot:item.member.lastname="{ item }">
      <!-- <div>
        {{ item.member.lastname }}
      </div> -->
      <div v-if="item.participant && item.participant.length > 0">
        <div v-for="(member, index) in item.participant" :key="index">
          {{ member.lastname }}
        </div>
      </div>
      <div v-else-if="item.isGroup">{{ item.contactLastname }}</div>
      <div v-else>-</div>
    </template>
    <template v-slot:item.member.sex="{ item }">
      <div v-if="item.participant && item.participant.length > 0">
        <div v-for="(member, index) in item.participant" :key="index">
          {{ member.sex | formatGender }}
        </div>
      </div>
      <div v-else>-</div>
    </template>
    <template v-slot:item.member.tel="{ item }">
      <div v-if="item.member.tel">
        {{ item.member.tel }}
      </div>
      <div v-else>-</div>
    </template>
    <template v-slot:item.member.email="{ item }">
      <div v-if="item.member.email">
        {{ item.member.email }}
      </div>
      <div v-else>-</div>
    </template>
    <template v-slot:item.age="{ item }">
      <div v-if="item.participant && item.participant.length > 0">
        <div v-for="(member, index) in item.participant" :key="index">
          {{ member.birthday | formatAge }}
        </div>
      </div>
      <div v-else>-</div>
    </template>
    <template v-slot:item.type="{ item }">
      {{ item.type | bookingType }}
    </template>
    <template v-slot:item.schedule.date="{ item }">
      {{ item.schedule.date | formatDateMain }}
    </template>
    <template v-slot:item.member.address.province="{ item }">
      {{
        item.member.address.province.text
          ? item.member.address.province.text
          : item.member.address.province || "-"
      }}
    </template>
    <template v-slot:item.member.address.subDistrict="{ item }">
      {{
        item.member.address.subDistrict ? item.member.address.subDistrict : "-"
      }}
    </template>
    <template v-slot:item.member.address.district="{ item }">
      {{ item.member.address.district ? item.member.address.district : "-" }}
    </template>
    <template v-slot:item.member.address.postcode="{ item }">
      {{ item.member.address.postcode ? item.member.address.postcode : "-" }}
    </template>
    <template v-slot:item.time="{ item }">
      {{ formatSchedule(item.schedule.startTime, item.schedule.endTime) }}
    </template>
    <template v-slot:item.orderStatus="{ item }">
      <div
        v-if="item.orderStatus === 'paid' && item.price.summary <= 0"
        :class="statusColor(item.orderStatus)"
      >
        คอนเฟิร์ม
      </div>
      <div v-else :class="statusColor(item.orderStatus)">
        {{ item.orderStatus | formatStatus }}
      </div>
    </template>
    <template v-slot:item.price.total="{ item }">
      {{ item.price ? formatCurrency(item.price.total) : "" }}
    </template>
    <template v-slot:item.price.countYoung="{ item }">
      <div v-if="!item.isGroup">
        {{ item.price.countYoung ? item.price.countYoung : 0 }}
      </div>
      <div v-else>
        {{ item.participants[0].count + item.participants[1].count || 0, }}
      </div>
    </template>
    <template v-slot:item.price.countStudent="{ item }">
      <div v-if="!item.isGroup">
        {{ item.price.countStudent ? item.price.countStudent : 0 }}
      </div>
      <div v-else>
        {{ item.participants[2].count + item.participants[3].count || 0, }}
      </div>
    </template>
    <template v-slot:item.price.countAdult="{ item }">
      <div v-if="!item.isGroup">
        {{ item.price.countAdult ? item.price.countAdult : 0 }}
      </div>
      <div v-else>
        {{ item.participants[4].count || 0, }}
      </div>
    </template>
    <template v-slot:item.price.countSenior="{ item }">
      <div v-if="!item.isGroup">
        {{ item.price.countSenior ? item.price.countSenior : 0 }}
      </div>
      <div v-else>
        {{ item.participants[5].count || 0, }}
      </div>
    </template>
    <template v-slot:item.price.total="{ item }">
      {{ item.price.total ? formatCurrency(item.price.total) : 0 }}
    </template>
    <template v-slot:item.price.discount="{ item }">
      {{ item.price.discount ? formatCurrency(item.price.discount) : 0 }}
    </template>
    <template v-slot:item.price.summary="{ item }">
      {{ item.price.summary ? formatCurrency(item.price.summary) : 0 }}
    </template>
  </v-data-table>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    statusColor(value) {
      switch (value) {
        case "waiting":
          return "orange--text";
        case "pending":
          return "orange--text";
        case "paid":
          return "green--text";
        case "confirmed":
          return "green--text";
        case "canceled":
          return "red--text";
        case "rejected":
          return "red--text";
        default:
          return "";
      }
    },
    formatDate(val) {
      if (!val) return;
      return dayjs(val).format("DD/MM/BBBB");
    },
  },
  computed: {
    attrs() {
      return {
        ...this.$attrs,
        headers: this.headers,
        itemsPerPage: 5,
      };
    },
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
  },
};
</script>
