<template>
  <v-card>
    <v-card-title> </v-card-title>
    <v-data-table
      :footer-props="footer"
      :headers="headers"
      :items="items"
      :search="search"
      disable-sort
      disable-filtering
      disable-pagination
      no-data-text="ไม่พบข้อมูล"
      no-results-text="ไม่พบข้อมูล"
    >
      <template v-slot:item.date="{ item }">
        <div class="">
          {{ item.date | formatMiniDate }}
        </div>
      </template>
      <template v-slot:item.time="{ item }">
        <div class="">
          {{ formatSchedule(item.startTime, item.endTime) }}
        </div>
      </template>
      <template v-slot:item.time="{ item }">
        <div class="">
          {{ formatSchedule(item.startTime, item.endTime) }}
        </div>
      </template>
      <template v-slot:item.type="{ item }">
        <div class="">
          {{ item.type | bookingType }}
        </div>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          class="font-weight-bold"
          color="green"
          dark
          @click="
            onChooseSchedule(item.scheduleId, item.startTime, item.endTime)
          "
          >เลือก</v-btn
        >
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    footer() {
      return {
        "items-per-page-text": "รายการต่อหน้า",
        "items-per-page-all-text": "ทั้งหมด",
      };
    },
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "วันที่", value: "date" },
        { text: "รอบเวลา", value: "time" },
        { text: "ประเภท", value: "type" },
        { text: "", value: "action" },
      ],
    };
  },
  methods: {
    onChooseSchedule(scheduleId, startTime, endTime) {
      const payload = {
        scheduleId,
        startTime,
        endTime,
      };
      this.$emit("input", payload);
    },
  },
};
</script>
