var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({staticClass:"elevation-3",attrs:{"no-data-text":"ไม่พบข้อมูล","no-results-text":"ไม่พบข้อมูล","headers":_vm.headers,"items":_vm.items,"items-per-page":5,"footer-props":_vm.footer},scopedSlots:_vm._u([{key:"item.orderId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderId)+" ")]}},{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdAt))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.member.firstname)+" "+_vm._s(item.member.lastname)+" ")]}},{key:"item.organizationName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.organizationName ? item.organizationName : "-")+" ")]}},{key:"item.member.firstname",fn:function(ref){
var item = ref.item;
return [(item.participant && item.participant.length > 0)?_c('div',_vm._l((item.participant),function(member,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(member.firstname)+" ")])}),0):(item.isGroup)?_c('div',[_vm._v(_vm._s(item.contactFirstname))]):_c('div',[_vm._v("-")])]}},{key:"item.member.lastname",fn:function(ref){
var item = ref.item;
return [(item.participant && item.participant.length > 0)?_c('div',_vm._l((item.participant),function(member,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(member.lastname)+" ")])}),0):(item.isGroup)?_c('div',[_vm._v(_vm._s(item.contactLastname))]):_c('div',[_vm._v("-")])]}},{key:"item.member.sex",fn:function(ref){
var item = ref.item;
return [(item.participant && item.participant.length > 0)?_c('div',_vm._l((item.participant),function(member,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm._f("formatGender")(member.sex))+" ")])}),0):_c('div',[_vm._v("-")])]}},{key:"item.member.tel",fn:function(ref){
var item = ref.item;
return [(item.member.tel)?_c('div',[_vm._v(" "+_vm._s(item.member.tel)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.member.email",fn:function(ref){
var item = ref.item;
return [(item.member.email)?_c('div',[_vm._v(" "+_vm._s(item.member.email)+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.age",fn:function(ref){
var item = ref.item;
return [(item.participant && item.participant.length > 0)?_c('div',_vm._l((item.participant),function(member,index){return _c('div',{key:index},[_vm._v(" "+_vm._s(_vm._f("formatAge")(member.birthday))+" ")])}),0):_c('div',[_vm._v("-")])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("bookingType")(item.type))+" ")]}},{key:"item.schedule.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateMain")(item.schedule.date))+" ")]}},{key:"item.member.address.province",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.member.address.province.text ? item.member.address.province.text : item.member.address.province || "-")+" ")]}},{key:"item.member.address.subDistrict",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.member.address.subDistrict ? item.member.address.subDistrict : "-")+" ")]}},{key:"item.member.address.district",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.member.address.district ? item.member.address.district : "-")+" ")]}},{key:"item.member.address.postcode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.member.address.postcode ? item.member.address.postcode : "-")+" ")]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatSchedule(item.schedule.startTime, item.schedule.endTime))+" ")]}},{key:"item.orderStatus",fn:function(ref){
var item = ref.item;
return [(item.orderStatus === 'paid' && item.price.summary <= 0)?_c('div',{class:_vm.statusColor(item.orderStatus)},[_vm._v(" คอนเฟิร์ม ")]):_c('div',{class:_vm.statusColor(item.orderStatus)},[_vm._v(" "+_vm._s(_vm._f("formatStatus")(item.orderStatus))+" ")])]}},{key:"item.price.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.total ? _vm.formatCurrency(item.price.total) : 0)+" ")]}},{key:"item.price.countYoung",fn:function(ref){
var item = ref.item;
return [(!item.isGroup)?_c('div',[_vm._v(" "+_vm._s(item.price.countYoung ? item.price.countYoung : 0)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.participants[0].count + item.participants[1].count || 0)+" ")])]}},{key:"item.price.countStudent",fn:function(ref){
var item = ref.item;
return [(!item.isGroup)?_c('div',[_vm._v(" "+_vm._s(item.price.countStudent ? item.price.countStudent : 0)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.participants[2].count + item.participants[3].count || 0)+" ")])]}},{key:"item.price.countAdult",fn:function(ref){
var item = ref.item;
return [(!item.isGroup)?_c('div',[_vm._v(" "+_vm._s(item.price.countAdult ? item.price.countAdult : 0)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.participants[4].count || 0)+" ")])]}},{key:"item.price.countSenior",fn:function(ref){
var item = ref.item;
return [(!item.isGroup)?_c('div',[_vm._v(" "+_vm._s(item.price.countSenior ? item.price.countSenior : 0)+" ")]):_c('div',[_vm._v(" "+_vm._s(item.participants[5].count || 0)+" ")])]}},{key:"item.price.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.discount ? _vm.formatCurrency(item.price.discount) : 0)+" ")]}},{key:"item.price.summary",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.summary ? _vm.formatCurrency(item.price.summary) : 0)+" ")]}}])},'v-data-table',_vm.attrs,false),Object.assign({}, _vm.$listeners)),[_vm._l((_vm.$slots),function(_,name){return _vm._t(name,null,{"slot":name})})],2)}
var staticRenderFns = []

export { render, staticRenderFns }