<template>
  <div>
    <Loading v-if="loading" />
    <v-container>
      <div>
        <v-row class="mt-2" ref="alert">
          <v-col cols="12" class="py-0">
            <h2 class="text-secondary header">รายงานออเดอร์</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <Alert
              v-model="alert.show"
              :title="alert.title"
              :message="alert.message"
              :type="alert.type"
            ></Alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-select
              @change="getOrderReport()"
              :items="dateTypesItems"
              item-text="text"
              item-value="value"
              v-model="dateType"
              label="ประเภทวันที่"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <DatePickerRange @input="getOrderReport($event)" />
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              @change="getOrderReport()"
              :items="orderStatusItems"
              item-text="text"
              item-value="value"
              v-model="orderStatus"
              label="สถานะ"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              @change="getOrderReport()"
              :items="typeItems"
              item-text="text"
              item-value="value"
              v-model="type"
              label="ประเภท"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3">
            <v-select
              @change="getOrderReport()"
              :items="branchItems"
              item-text="text"
              item-value="value"
              v-model="branch"
              label="สาขา"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <DataTable :headers="dataTable.headers" :items="dataTable.items">
            </DataTable>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <GroupButton
              :count="orderReport.count"
              :summary="orderReport.summary"
              :data-report="orderReport.data"
              :labels-report="orderReport.labels"
              :fileName="fileName"
              @send-to-email="sendToEmail(orderReport.data)"
            />
          </v-col>
        </v-row>
      </div>
      <div class="mt-10">
        <v-row>
          <v-col cols="12" class="py-0">
            <h2 class="text-secondary header">รายงานออเดอร์ต่อรอบ</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <DatePicker label="เลือกวันที่" @input="getScheduleDate($event)" />
          </v-col>
          <v-col cols="12" md="3"></v-col>
          <v-col cols="12" md="3">
            <v-select
              :items="typeItems"
              v-model="scheduleOption.type"
              item-text="text"
              item-value="value"
              label="ประเภท"
              @change="getScheduleDate(null)"
            ></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              :items="branchItems"
              v-model="scheduleOption.branch"
              item-text="text"
              item-value="value"
              label="สาขา"
              @change="getScheduleDate(null)"
            ></v-select>
          </v-col>
          <v-col cols="12">
            <ScheduleTimeTable
              @input="getOrderBySchedule($event)"
              :items="scheduleOption.data"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4"></v-col>
          <v-col cols="12" md="4"></v-col>
          <v-col v-if="orderSchedule" cols="12" md="4">
            <v-select
              :items="orderStatusItems"
              v-model="orderScheduleOptions.orderStatus"
              item-text="text"
              item-value="value"
              label="สถานะ"
              @change="getOrderBySchedule()"
            ></v-select>
          </v-col>
          <v-col ref="reportSchedule" cols="12">
            <ReportScheduleTimeDataTable
              :items="orderSchedule"
              v-if="orderSchedule"
            />
          </v-col>
          <v-col v-if="orderSchedule" cols="12">
            <GroupButton
              :count="scheduleOrderReport.count"
              :summary="scheduleOrderReport.summary"
              :data-report="scheduleOrderReport.data"
              :labels-report="scheduleOrderReport.labels"
              :fileName="fileNameScheduleReport"
              @send-to-email="sendToEmail(scheduleOrderReport.data)"
            />
          </v-col>
        </v-row>
      </div>
      <div class="mt-10">
        <v-row>
          <v-col cols="12" class="py-0">
            <h2 class="text-secondary header">รายงานการซื้อแพ็กเกจ</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="7">
            <DatePickerRange @input="getPackageMemberLog($event)" />
          </v-col>
          <v-col cols="12" md="2">
            <v-spacer></v-spacer>
          </v-col>
          <v-col cols="12" md="3">
            <v-select
              @change="getPackageMemberLog()"
              :items="branchItems"
              item-text="text"
              item-value="value"
              v-model="branch2"
              label="สาขา"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <DataTable :headers="dataTable2.headers" :items="dataTable2.items">
            </DataTable>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <GroupButton
              :count="memberPackageReport.count"
              :summary="memberPackageReport.summary"
              :data-report="memberPackageReport.data"
              :labels-report="memberPackageReport.labels"
              fileName="package-report"
              @send-to-email="sendToEmailPackage()"
            />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import axios from "../../utils/axios";
import dayjs from "dayjs";
import DatePickerRange from "@/components/common/DatePickerRange.vue";
import ReportScheduleTimeDataTable from "@/components/Report/ReportScheduleTimeDataTable.vue";
import ScheduleTimeTable from "@/components/Report/ScheduleTimeTable.vue";
import DataTable from "@/components/common/DataTable.vue";
import DatePicker from "@/components/DatePicker.vue";
import GroupButton from "@/components/Report/GroupButton.vue";
import Alert from "@/components/common/Alert.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    ScheduleTimeTable,
    ReportScheduleTimeDataTable,
    DatePickerRange,
    DatePicker,
    DataTable,
    GroupButton,
    Loading,
    Alert,
  },
  computed: {
    ...mapGetters({
      info: "User/info",
    }),

    branchItems() {
      if (this.info.profile.branch === "trang") {
        return [{ text: "ตรัง", value: "trang" }];
      }
      if (this.info.profile.branch === "sathorn") {
        return [{ text: "สาทร", value: "sathorn" }];
      }
      if (this.info.profile.branch === "all") {
        return [
          { text: "ตรัง", value: "trang" },
          { text: "สาทร", value: "sathorn" },
        ];
      }
      return [{ text: "", value: "" }];
    },
    dateTypesItems() {
      return [
        { text: "วันที่ทำรายการ", value: "createdOn" },
        { text: "วันที่จอง", value: "schedule" },
        { text: "วันที่ชำระเงิน", value: "paidDate" },
      ];
    },
    orderStatusItems() {
      return [
        { text: "ทั้งหมด", value: "all" },
        { text: "ชำระเงินแล้ว / คอนเฟิร์ม", value: "paid" },
        { text: "รอคอนเฟิร์ม", value: "waiting" },
        { text: "ปฏิเสธ", value: "rejected" },
        { text: "ยกเลิก", value: "canceled" },
      ];
    },
    typeItems() {
      return [
        { text: "ทั้งหมด", value: "all" },
        { text: "ปกติ", value: "normal" },
        { text: "กลุ่ม", value: "group" },
        { text: "กิจกรรม", value: "event" },
      ];
    },
    fileName() {
      if (this.date.length > 0) {
        const startDate = dayjs(this.date[0]).locale("th").format("DD-MM-YYYY");
        const endDate = dayjs(this.date[1]).locale("th").format("DD-MM-YYYY");
        return `report-order-${startDate} - ${endDate}`;
      }
      return "report-order";
    },
    fileNameScheduleReport() {
      const date = dayjs(this.scheduleOption.date)
        .locale("th")
        .format("DD-MM-YYYY");
      return `${date}[${this.orderScheduleOptions.time}]`;
    },
  },
  data() {
    return {
      loading: false,
      branch: "",
      branch2: "",
      orderStatus: "all",
      dateType: "createdOn",
      type: "all",
      alert: {
        show: false,
        title: "",
        message: "",
        type: "",
      },
      orderReport: {
        count: null,
        summary: null,
        data: [],
        labels: {},
      },
      scheduleOrderReport: {
        count: null,
        summary: null,
        data: [],
        labels: {},
      },
      memberPackageReport: {
        count: null,
        data: [],
        summary: null,
        labels: {},
      },
      date: [],
      date2: [],
      selected: "รายได้",
      dataTable: {
        headers: [
          {
            text: "OrderId",
            align: "start",
            sortable: false,
            value: "orderId",
          },
          { text: "ชื่อจริง", value: "member.firstname" },
          { text: "นามสกุล", value: "member.lastname" },
          { text: "ราคาทั้งหมด", value: "price.total" },
        ],
        items: [],
      },
      dataTable2: {
        headers: [
          {
            text: "วันที่ทำรายการ",
            align: "start",
            sortable: false,
            value: "orderId",
          },
          { text: "ชื่อแพ็กเกจ", value: "memberPackage.package.title" },
          { text: "ชื่อ - นามสกุล", value: "name" },
          { text: "รวมทั้งสิ้น (บาท)", value: "summary" },
        ],
        items: [],
      },
      scheduleOption: {
        data: [],
        date: dayjs().format("YYYY-MM-DD"),
        type: "all",
        branch: "trang",
      },
      orderSchedule: null,
      orderScheduleOptions: {
        time: "",
        scheduleId: "",
        orderStatus: "all",
        type: "all",
      },
    };
  },
  async mounted() {
    const branch =
      this.info.profile.branch === "all" ? "trang" : this.info.profile.branch;
    this.branch = branch;
    this.branch2 = branch;
    this.scheduleOption.branch = branch;
    await this.getOrderReport([
      dayjs().format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ]);
    await this.getPackageMemberLog([
      dayjs().format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ]);
    await this.getScheduleDate();
  },
  methods: {
    async getOrderReport(event) {
      try {
        this.loading = true;
        if (event) {
          this.date = event;
        }
        const {
          data: { data },
        } = await axios.get(
          `/report/order?startDate=${this.date[0]}&endDate=${this.date[1]}&orderStatus=${this.orderStatus}&branch=${this.branch}&type=${this.type}&dateType=${this.dateType}`
        );
        this.formatExportOrderReport(data);
        this.dataTable.headers = [
          { text: "วันที่ทำรายการ", value: "createdOn", width: "9rem" },
          { text: "หมายเลขออเดอร์", value: "orderId", width: "9rem" },
          { text: "ชื่อองค์กร", value: "organizationName", width: "11rem" },
          { text: "ชื่อจริง", value: "member.firstname", width: "9rem" },
          { text: "นามสกุล", value: "member.lastname", width: "9rem" },
          { text: "อายุ", value: "age", width: "9rem" },
          { text: "เพศ", value: "member.sex", width: "7rem" },
          { text: "เบอร์โทรศัพท์", value: "member.tel", width: "9rem" },
          { text: "Email", value: "member.email", width: "7rem" },
          { text: "ตำบล", value: "member.address.subDistrict", width: "9rem" },
          { text: "อำเภอ", value: "member.address.district", width: "9rem" },
          {
            text: "จังหวัด",
            value: "member.address.province",
            width: "9rem",
          },
          {
            text: "รหัสไปรษณีย์",
            value: "member.address.postcode",
            width: "9rem",
          },
          {
            text: "ประเภทการจอง",
            value: "type",
            width: "9rem",
            align: "center",
          },
          { text: "จองวันที่", value: "schedule.date", width: "7rem" },
          { text: "รอบเวลา", value: "time", width: "10rem" },
          { text: "สถานะ", value: "orderStatus", width: "7rem" },
          {
            text: "จำนวนผู้เข้าร่วม",
            value: "joinQty",
            align: "center",
            width: "9rem",
          },
          {
            text: "เด็ก",
            value: "price.countYoung",
            align: "center",
            width: "8rem",
          },
          {
            text: "นักเรียน / นักศึกษา",
            value: "price.countStudent",
            align: "center",
            width: "11rem",
          },
          {
            text: "ผู้ใหญ่",
            value: "price.countAdult",
            align: "center",
            width: "8rem",
          },
          {
            text: "ผู้สูงอายุ",
            value: "price.countSenior",
            align: "center",
            width: "7rem",
          },
          {
            text: "ราคาเต็ม (บาท)",
            value: "price.total",
            align: "center",
            width: "9rem",
          },
          {
            text: "ส่วนลด (บาท)",
            value: "price.discount",
            align: "center",
            width: "9rem",
          },
          {
            text: "รวมทั้งสิ้น (บาท)",
            value: "price.summary",
            align: "center",
            width: "9rem",
          },
        ];
        this.dataTable.items = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getPackageMemberLog(event) {
      try {
        this.loading = true;
        if (event) {
          this.date2 = event;
        }
        const {
          data: { data },
        } = await axios.get(
          `/package-member/log/all?startDate=${this.date2[0]}&endDate=${this.date2[1]}&branch=${this.branch2}`
        );
        this.formatExportMemberPackageReport(data);
        this.dataTable2.headers = [
          { text: "วันที่ทำรายการ", value: "createdAt", width: "9rem" },
          {
            text: "ชื่อแพ็กเกจ",
            value: "memberPackage.package.title",
            width: "9rem",
          },
          { text: "ชื่อ - นามสกุล", value: "name", width: "9rem" },
          {
            text: "รวมทั้งสิ้น (บาท)",
            value: "price",
            align: "center",
            width: "9rem",
          },
        ];
        this.dataTable2.items = data;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getScheduleDate(event) {
      try {
        this.loading = true;
        if (event) {
          this.scheduleOption.date = event;
        }
        const date = this.scheduleOption.date || dayjs().format("YYYY-MM-DD");
        const {
          data: { data },
        } = await axios.get(
          `schedule/date/search?date=${date}&branch=${this.scheduleOption.branch}&type=${this.scheduleOption.type}`
        );
        this.scheduleOption.data = data;
        this.orderSchedule = null;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    async getOrderBySchedule(object) {
      try {
        this.loading = true;
        if (object && object.scheduleId && object.startTime && object.endTime) {
          this.orderScheduleOptions.scheduleId = object.scheduleId;
          this.orderScheduleOptions.time = `${object.startTime} - ${object.endTime}`;
        }
        const {
          data: { data },
        } = await axios.get(
          `report/${this.orderScheduleOptions.scheduleId}/schedule?branch=${this.scheduleOption.branch}&orderStatus=${this.orderScheduleOptions.orderStatus}&type=${this.orderScheduleOptions.type}`
        );
        this.orderSchedule = data;
        this.formatExportScheduleOrderReport(data);
        const el = this.$refs.reportSchedule;
        setTimeout(() => {
          el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 200);
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    formatExportOrderReport(data) {
      const arr = [];
      let summary = 0;
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const { orderId, price } = data[i];
          if (price.summary) {
            summary += price.summary;
          }

          if (data[i].participant && data[i].participant.length > 0) {
            for (let j = 0; j < data[i].participant.length; j++) {
              const total = data[i].price?.total || 0;
              const summarys = price.summary || 0;
              const payload = {
                orderId,
                createdAt: dayjs(data[i].createdAt).format("DD/MM/BBBB"),
                joinedAt: dayjs(data[i].schedule.date).format("DD/MM/BBBB"),
                organizationName: "-",
                firstname: data[i].participant[j].firstname,
                lastname: data[i].participant[j].lastname,
                sex: this.formatGender(data[i].participant[j].sex),
                age: this.formatAge(data[i].participant[j].birthday),
                tel: data[i].member?.tel || "-",
                email: data[i].member?.email || "-",
                subDistrict: data[i].member.address?.subDistrict || "-",
                district: data[i].member?.address?.district || "-",
                province: data[i].member?.address?.province?.text
                  ? data[i].member?.address?.province?.text
                  : data[i].member?.address?.province || "-",
                postcode: data[i].member?.address?.postcode || "-",
                type: this.formatType(data[i].type),
                time: this.formatSchedule(
                  data[i].schedule.startTime,
                  data[i].schedule.endTime
                ),
                orderStatus:
                  data[i].orderStatus === "paid" && data[i].price.summary <= 0
                    ? "คอนเฟิร์ม"
                    : this.formatStatus(data[i].orderStatus),
                joinQty: data[i].joinQty || 0,
                countYoung: data[i].price?.countYoung || 0,
                countStudent: data[i].price?.countStudent || 0,
                countAdult: data[i].price?.countAdult || 0,
                countSenior: data[i].price?.countSenior || 0,
                total: total,
                discount: total - summarys,
                summary: summarys,
              };
              arr.push(payload);
            }
          }

          if (data[i].isGroup) {
            const total = data[i].price?.total || 0;
            const summarys = price.summary || 0;
            const payload = {
              orderId,
              createdAt: dayjs(data[i].createdAt).format("DD/MM/BBBB"),
              joinedAt: dayjs(data[i].schedule.date).format("DD/MM/BBBB"),
              organizationName: data[i].organizationName,
              firstname: data[i].contactFirstname,
              lastname: data[i].contactLastname,
              sex: "-",
              age: "-",
              tel: data[i].member?.tel || "-",
              email: data[i].member?.email || "-",
              subDistrict: data[i].member.address?.subDistrict || "-",
              district: data[i].member?.address?.district || "-",
              province: data[i].member?.address?.province?.text
                ? data[i].member?.address?.province?.text
                : data[i].member?.address?.province || "-",
              postcode: data[i].member?.address?.postcode || "-",
              type: this.formatType(data[i].type),
              time: this.formatSchedule(
                data[i].schedule.startTime,
                data[i].schedule.endTime
              ),
              orderStatus:
                data[i].orderStatus === "paid" && data[i].price.summary <= 0
                  ? "คอนเฟิร์ม"
                  : this.formatStatus(data[i].orderStatus),
              joinQty: data[i].joinQty || 0,
              countYoung:
                data[i].participants[0].count + data[i].participants[1].count,
              countStudent:
                data[i].participants[2].count + data[i].participants[3].count ||
                0,
              countAdult: data[i].participants[4].count || 0,
              countSenior: data[i].participants[5].count || 0,
              total: total,
              discount: total - summarys,
              summary: 0,
            };
            arr.push(payload);
          }
        }
      }
      this.orderReport.labels = {
        orderId: "หมายเลขออเดอร์",
        createdAt: "วันที่ทำรายการ",
        joinedAt: "วันที่เข้าร่วม",
        organizationName: "ชื่อองค์กร",
        firstname: "ชื่อจริง",
        lastname: "นามสกุล",
        sex: "เพศ",
        age: "อายุ",
        tel: "เบอร์โทรศัพท์",
        email: "Email",
        subDistrict: "ตำบล",
        district: "อำเภอ",
        province: "จังหวัด",
        postcode: "รหัสไปรษณีย์",
        type: "ประเภทการจอง",
        time: "รอบเวลา",
        orderStatus: "สถานะ",
        joinQty: "จำนวนผู้เข้าร่วม",
        countYoung: "เด็ก",
        countStudent: "นักเรียน / นักศึกษา",
        countAdult: "ผู้ใหญ่",
        countSenior: "ผู้สูงอายุ",
        total: "ราคาก่อนลด (บาท)",
        discount: "ส่วนลด (บาท)",
        summary: "รวมทั้งสิ้น (บาท)",
      };
      // console.log("orderReport.data", arr);
      this.orderReport.data = arr;
      this.orderReport.summary = summary;
      this.orderReport.count = data.length;
    },
    formatExportScheduleOrderReport(data) {
      const arr = [];
      let summary = 0;
      if (data) {
        for (let i = 0; i < data.length; i++) {
          const { orderId, price } = data[i];
          if (price.summary) {
            summary += price.summary;
          }
          if (data[i].participant && data[i].participant.length > 0) {
            for (let j = 0; j < data[i].participant.length; j++) {
              const total = data[i].price?.total || 0;
              const summarys = price.summary || 0;
              const payload = {
                orderId,
                createdAt: dayjs(data[i].createdAt).format("DD/MM/BBBB"),
                joinedAt: dayjs(data[i].schedule.date).format("DD/MM/BBBB"),
                organizationName: "-",
                firstname: data[i].participant[j].firstname,
                lastname: data[i].participant[j].lastname,
                sex: this.formatGender(data[i].participant[j].sex),
                age: this.formatAge(data[i].participant[j].birthday),
                tel: data[i].member?.tel || "-",
                email: data[i].member?.email || "-",
                subDistrict: data[i].member.address?.subDistrict || "-",
                district: data[i].member?.address?.district || "-",
                province: data[i].member?.address?.province?.text
                  ? data[i].member?.address?.province?.text
                  : data[i].member?.address?.province || "-",
                postcode: data[i].member?.address?.postcode || "-",
                type: this.formatType(data[i].type),
                time: this.formatSchedule(
                  data[i].schedule.startTime,
                  data[i].schedule.endTime
                ),
                orderStatus:
                  data[i].orderStatus === "paid" && data[i].price.summary <= 0
                    ? "คอนเฟิร์ม"
                    : this.formatStatus(data[i].orderStatus),
                joinQty: data[i].joinQty || 0,
                countYoung: data[i].price.countYoung || 0,
                countStudent: data[i].price.countStudent || 0,
                countAdult: data[i].price.countAdult || 0,
                countSenior: data[i].price.countSenior || 0,
                total: total,
                discount: total - summarys,
                summary: summarys,
              };
              arr.push(payload);
            }
          }
        }
      }
      this.scheduleOrderReport.labels = {
        orderId: "หมายเลขออเดอร์",
        createdAt: "วันที่ทำรายการ",
        joinedAt: "วันที่เข้าร่วม",
        organizationName: "ชื่อองค์กร",
        firstname: "ชื่อจริง",
        lastname: "นามสกุล",
        sex: "เพศ",
        age: "อายุ",
        tel: "เบอร์โทรศัพท์",
        email: "Email",
        subDistrict: "ตำบล",
        district: "อำเภอ",
        province: "จังหวัด",
        postcode: "รหัสไปรษณีย์",
        type: "ประเภทการจอง",
        time: "รอบเวลา",
        orderStatus: "สถานะ",
        joinQty: "จำนวนผู้เข้าร่วม",
        countYoung: "เด็ก",
        countStudent: "นักเรียน / นักศึกษา",
        countAdult: "ผู้ใหญ่",
        countSenior: "ผู้สูงอายุ",
        total: "ราคาก่อนลด (บาท)",
        discount: "ส่วนลด (บาท)",
        summary: "รวมทั้งสิ้น (บาท)",
      };
      this.scheduleOrderReport.data = arr;
      this.scheduleOrderReport.summary = summary;
      this.scheduleOrderReport.count = data.length;
      // console.log(arr);
    },
    formatExportMemberPackageReport(data) {
      const arr = [];
      let summary = 0;
      for (let i = 0; i < data.length; i++) {
        const { price } = data[i];
        if (price) {
          summary += price;
        }
        const payload = {
          createdAt: dayjs(data[i].createdAt).format("DD/MM/YYYY HH:mm"),
          memberPackageTitle: data[i].memberPackage.package.title,
          name: data[i].member.firstname + " " + data[i].member.lastname,
          total: data[i].price || 0,
        };
        arr.push(payload);
      }
      this.memberPackageReport.labels = {
        createdAt: "วันที่ทำรายการ",
        memberPackageTitle: "ชื่อแพ็กเกจ",
        name: "ชื่อ - นามสกุล",
        total: "รวมทั้งสิ้น (บาท)",
      };
      this.memberPackageReport.data = arr;
      this.memberPackageReport.summary = summary;
      this.memberPackageReport.count = data.length;
    },
    async sendToEmail(data) {
      try {
        this.loading = true;
        await axios.post(
          `/email/send-email?startDate=${this.date[0]}&endDate=${this.date[1]}`,
          data
        );
        this.alertShow(
          "success",
          "ดำเนินการเสร็จสิ้น",
          "ระบบได้ส่งข้อมูลไปยังอีเมลสำเร็จแล้ว"
        );
        const el = this.$refs.alert;
        setTimeout(() => {
          el.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }, 200);
      } catch (error) {
        this.alertShow("error", "เกิดผิดพลาด", "กรุณาตรวจสอบความถูกต้อง");
      } finally {
        this.loading = false;
      }
    },
    async sendToEmailPackage() {
      try {
        this.loading = true;
        await axios.post(
          `/email/send-email/package`,
          this.memberPackageReport.data
        );
        this.alertShow(
          "success",
          "ดำเนินการเสร็จสิ้น",
          "ระบบได้ส่งข้อมูลไปยังอีเมลสำเร็จแล้ว"
        );
      } catch (error) {
        this.alertShow("error", "เกิดผิดพลาด", "กรุณาตรวจสอบความถูกต้อง");
      } finally {
        this.loading = false;
      }
    },
    alertShow(type, title, message) {
      this.alert.show = true;
      this.alert.title = title;
      this.alert.type = type;
      this.alert.message = message;
      setTimeout(() => {
        this.alert.show = false;
      }, 10000);
    },
    formatSchedule(startTime, endTime) {
      if (startTime === "00:00" && endTime === "23:59") {
        return "Allday";
      }
      return `${startTime} - ${endTime} น.`;
    },
    formatStatus(val) {
      switch (val) {
        case "waiting":
          return "รอคอนเฟิร์ม";
        case "pending":
          return "รอชำระเงิน";
        case "paid":
          return "ชำระเงินแล้ว";
        case "confirmed":
          return "คอนเฟิร์ม";
        case "canceled":
          return "ยกเลิก";
        case "rejected":
          return "ปฏิเสธ";
        default:
          return "";
      }
    },
    formatType(val) {
      switch (val) {
        case "normal":
          return "ปกติ";
        case "group":
          return "กลุ่ม";
        case "event":
          return "กิจกรรม";
        default:
          return val;
      }
    },
    formatGender(value) {
      switch (value) {
        case "male":
          return "ชาย";
        case "female":
          return "หญิง";
        default:
          return "ไม่ระบุ";
      }
    },
    formatAge(val) {
      return dayjs().diff(dayjs(val), "year");
    },
  },
};
</script>
