<template>
  <v-row>
    <v-col cols="12" md="4">
      <Card>
        <v-card-subtitle>รายได้</v-card-subtitle>
        <v-card-title class="d-flex justify-center">
          <h4>{{ formatCurrency(summary) || "0" }}</h4>
        </v-card-title>
        <v-card-subtitle class="text-right">บาท</v-card-subtitle>
      </Card>
    </v-col>
    <v-col cols="12" md="4">
      <Card>
        <v-card-subtitle>จำนวน</v-card-subtitle>
        <v-card-title class="d-flex justify-center">
          <h4>{{ count || "0" }}</h4>
        </v-card-title>
        <v-card-subtitle class="text-right">รายการ</v-card-subtitle>
      </Card>
    </v-col>
    <v-col cols="12" md="4">
      <v-row>
        <v-col cols="12">
          <JsonCSV :data="data" :labels="labels" :name="`${fileName}.csv`">
            <Button class="font-weight-bold py-7" color="green" dark block>
              <v-icon>mdi-file-export</v-icon>
              ส่งออก CSV
            </Button>
          </JsonCSV>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <Button
            @click="sendToEmail()"
            class="font-weight-bold py-7"
            color="primary"
            block
            dark
          >
            <v-icon>mdi-email-send</v-icon>
            ส่งไปที่ Email
          </Button>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import Button from "@/components/common/Button.vue";
import Card from "@/components/Report/Card.vue";
import JsonCSV from "vue-json-csv";
export default {
  props: {
    summary: {
      type: Number,
      default: 0,
    },
    count: {
      type: Number,
      default: 0,
    },
    dataReport: {
      type: Array,
      default: () => [],
    },
    labelsReport: {
      type: Object,
      default: () => ({}),
    },
    fileName: {
      type: String,
      default: "",
    },
  },
  computed: {
    data() {
      return this.dataReport;
    },
    labels() {
      return this.labelsReport;
    },
  },
  components: {
    Card,
    Button,
    JsonCSV,
  },
  data() {
    return {};
  },
  methods: {
    sendToEmail() {
      this.$emit("send-to-email");
    },
  },
};
</script>
